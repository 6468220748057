import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useCtaQuery = () => {
  const { allSanityCallToAction } = useStaticQuery(graphql`
    query {
      allSanityCallToAction {
        nodes {
          _id
          title
          subtitle
          ctaImage {
            ...ImageWithPreview
          }
          button {
            ...PageLink
          }
        }
      }
    }
  `);
  return allSanityCallToAction || {};
};
