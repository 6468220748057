import { useStaticQuery, graphql } from "gatsby";

import { QueryFragments } from "./queryFragments"; // eslint-disable-line

export const useJobPostQuery = () => {
  const { allSanityJobPost } = useStaticQuery(graphql`
    query {
      allSanityJobPost(sort: { fields: orderRank, order: ASC }) {
        jobPosts: nodes {
          title
          intro
          slug {
            current
          }
          featuredImage {
            ...ImageWithPreview
          }
          jobLink
          date(formatString: "DD MMMM YYYY")
        }
      }
    }
  `);
  return allSanityJobPost || {};
};
