/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';
import IntroBlock from '@components/other/introBlock';
import { useJobPostQuery } from '@querys/useJobPostQuery';
import { useCtaQuery } from '@querys/useCtaQuery';
import { SanityImage } from '@components/sanity/sanityImage';
import ArrowButton from '@components/UI/arrowButton';
import { Link } from 'gatsby';
import CtaBlock from '@components/pageModules/modules/ctaBlock';

const Careers = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { seo, title, subtitle } = page || {};
  const { jobPosts } = useJobPostQuery();
  const { nodes } = useCtaQuery();

  const [hover, setHover] = useState(false);

  // select item from nodes array that has in _id of "89921f56-d8cd-47b5-b437-fe549adb2ab7"
  const contactCta = nodes.find(
    (node) => node._id === '89921f56-d8cd-47b5-b437-fe549adb2ab7'
  );

  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />
      <div className='px-gutter pt-space-m'>
        <h2 className='blockH2'>Current Opportunities</h2>
        <div className='pt-space-s flex flex-col space-y-[100px]'>
          {jobPosts?.map((listing) => (
            <Link
              to={`/careers/${listing?.slug?.current}`}
              className='flex-col lg:grid grid-cols-10 gap-x-[58px] '
            >
              <h4 className='lg:hidden xl:block blockH6 text-grey col-start-1 col-end-3'>
                {listing?.date}
              </h4>
              <div className='col-start-1 xl:col-start-3 col-end-6 '>
                <h4 className='hidden lg:block xl:hidden blockH6 text-grey pb-2'>
                  {listing?.date}
                </h4>
                <h2 className='blockH4'>{listing?.title}</h2>
                <p className='line-clamp-5 blockH6 text-grey pt-5'>
                  {listing?.intro}
                </p>
                <Link
                  to={`/careers/${listing?.slug?.current}`}
                  className='blockH5 inline-block mt-[25px]'
                >
                  {' '}
                  <ArrowButton linkText='Read more' imageHover={hover} />
                </Link>
              </div>
              <div
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                className='rounded-[50px] col-start-6 col-end-11 aspect-w-4 aspect-h-3 relative overflow-hidden  mt-10 lg:mt-0'
              >
                <SanityImage
                  image={listing?.featuredImage}
                  className='absolute inset-0 w-full h-full object-cover'
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className='pt-space-m'>
        <CtaBlock reference={contactCta} />
      </div>
    </Layout>
  );
};

export default Careers;

export const pageQuery = graphql`
  query sanityCareersHomeQuery {
    page: sanityCareersHome {
      title
      subtitle
      seo {
        ...SEO
      }
    }
  }
`;
